import { useStaticQuery, graphql } from "gatsby"

export const useCompareTableData = () => {
  const data = useStaticQuery(graphql`
    query getComparisonTable {
      allComparisonData {
        nodes {
          Indicator
          Variable
          Group1
          Group2
          Stat1
          Stat2
          _xxpxvalue
          Flag
        }
      }
    }
  `)

  return data.allComparisonData.nodes
}