import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import { useCompareTableData } from '../hooks/useCompareTableData'
import { Filter } from './util/filter'
import CompareTable from './CompareTable'

const filter = new Filter()

export default function CompareTool () {
  const [selectedComparison, setSelectedComparison] = useState(filter.variables[0])
  const [options, setOptions] = useState(null)
  const [slicedData, setSlicedData] = useState(null)
  const [tableData, setTableData] = useState(null)
  const data = useCompareTableData()

  /*
    This useEffect runs whenever the "selectedComparison" state value changes, 
    and when the component mounts on page load.
  */
  useEffect(() => {
    const selectedData = filter.sliceData(selectedComparison, data)

    const options = filter.initOptions(selectedComparison.value)

    setSlicedData(selectedData)
    setOptions(options)
  }, [selectedComparison])
  
  /*
    This useEffect runs whenever the "options" state value changes, and when 
    the component mounts on page load.
  */
  useEffect(() => {
    if (options) {
      const tableData = filter.filterData(slicedData, options, selectedComparison)
      setTableData(tableData)
    }
  }, [options])

  function onSelectionChange (key, selection) {
    const newOptions = filter.updateOptions(options, key, selection)
    setOptions(newOptions)
  }

  return (
    <div>
      <div className='row'>
        <div className='col-md-4'>
          <label for='compareBy'><strong>Compare by</strong></label>
          <Select
            aria-label='Compare by'
            id='compareBy'
            value={selectedComparison}
            options={filter.variables}
            onChange={setSelectedComparison}
          />
        </div>
        
        {
          options &&
          <>
            <div className='col-md-4' style={{ paddingTop: '32px' }}>
              <Select
                aria-label='Filter for column A'
                id='filterA'
                value={options.a.selection}
                options={options.a.options}
                onChange={(selection) => onSelectionChange('a', selection)}
                isOptionDisabled={option => option.isDisabled}
              />
            </div>
            <div className='col-md-4' style={{ paddingTop: '32px' }}>
              <Select
                aria-label='Filter for column B'
                id='filterB'
                value={options.b.selection}
                options={options.b.options}
                onChange={(selection) => onSelectionChange('b', selection)}
                isOptionDisabled={option => option.isDisabled}
              />
            </div>
          </>
        }
      </div>
      
      {
        tableData && <CompareTable data={tableData} options={options} />
      }
    </div>
  )
}