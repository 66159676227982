import React, { useState, useEffect } from 'react'

export default function CompareTable ({ data, options }) {
  const [rows, setRows] = useState(null)
  const [footnote, setFootnote] = useState(null)
  
  useEffect(() => {
    let footnotes = {}

    const rows = Object.entries(data).map(([key, row]) => {
      if (row.Flag === 'Not applicable' || row.Flag === 'Data sparse, interpret with caution') {
        footnotes = {
          ...footnotes,
          [key]: row.Flag
        }
      }

      return row
    })
    
    if (footnotes !== {}) {
      setFootnote(footnotes)
    }

    setRows(rows)
  }, [setFootnote, data])

  function renderRows () {
    return rows.map((row, index) => {
      const [backgroundA, backgroundB] = getCellBackground(row)

      return (
        <tr key={row.Indicator}>
          <td className='col-4'>{displayIndicator(row.Indicator, index.toString())}</td>
          <td className={`col-4 text-center ${backgroundA}`}>
            {displayStat(row.Stat1, row.Indicator)}
          </td>
          <td className={`col-4 text-center ${backgroundB}`}>
            {displayStat(row.Stat2, row.Indicator)}
          </td>
        </tr>
      )
    })
  }
  
  function displayIndicator (indicator, index) {
    return (
      <>
        {`${indicator} `}
        {(footnote && footnote[index]) && <sup>1</sup>}
      </>
    )
  }

  function isStatisticallySignificant (pValue) {
    if (pValue < 0.05) {
      return true
    } 
    
    return false
  }
  
  function getCellBackground (row) {
    const isSignificant = isStatisticallySignificant(row._xxpxvalue)
    
    let backgroundA
    let backgroundB

    // Reverse color coding of “Not covered by health insurance” and “Report workdays vary”
    // comparisons. Lower percentage indicates higher quality, so it should be shaded green.
    if (row.Indicator === '% not covered by health insurance' || row.Indicator === '% reporting days of work vary') {
      backgroundA = (isSignificant && parseInt(row.Stat1) < parseInt(row.Stat2)) ? 'table-success' : ''
      backgroundB = (isSignificant && parseInt(row.Stat2) < parseInt(row.Stat1)) ? 'table-success' : ''
    } else {
      backgroundA = (isSignificant && parseInt(row.Stat1) > parseInt(row.Stat2)) ? 'table-success' : ''
      backgroundB = (isSignificant && parseInt(row.Stat2) > parseInt(row.Stat1)) ? 'table-success' : ''
    }

    return [backgroundA, backgroundB]
  }
  
  function displayStat (stat, indicator) {
    if (indicator === 'Median monthly pay') {
      return `$${parseInt(stat).toLocaleString()}`
    }
    
    if (indicator.startsWith('%')) {
      return `${stat}%`
    }
    
    return stat
  }

  return (
    <>
      {
        rows 
          &&
            <>
              <table className='table table-bordered mt-4'>
                <thead>
                  <tr>
                    <th className='col-4 text-center'></th>
                    <th className='col-4 text-center'>{options.a.selection.label}</th>
                    <th className='col-4 text-center'>{options.b.selection.label}</th>
                  </tr>
                </thead>
                
                <tbody>
                  {renderRows()}
                </tbody>
              </table>
              
              {
                Object.keys(footnote).length > 0 ? (
                  <div>
                    <sup>1</sup> {Object.values(footnote)[0]}
                  </div>
                ) : null
              }
            </>
      }
    </>
  )
}