export class Filter {
  get options () {
    return {
      'Region': [
        { value: 'City of Chicago', label: 'City of Chicago' },
        { value: 'Suburban Cook County', label: 'Suburban Cook County' },
        { value: 'Other counties in Illinois', label: 'Other counties in Illinois' }
      ],
      'Age': [
        { value: '18-24', label: '18-24' },
        { value: '25-34', label: '25-34' },
        { value: '34-49', label: '34-49' },
        { value: '50+', label: '50+' }
      ],
      'Race/ethnicity': [
        { value: 'Asian', label: 'Asian' },
        { value: 'Black', label: 'Black' },
        { value: 'Hispanic', label: 'Hispanic/Latinx' },
        { value: 'White', label: 'White' },
        { value: 'Multiracial/other', label: 'Multiracial/other' }
      ],
      'Gender': [
        { value: 'Man', label: 'Man' },
        { value: 'Woman', label: 'Woman' },
        { value: 'Non-binary/other', label: 'Non-binary/other' }
      ],
      'Education': [
        { value: 'High school or less', label: 'High school or less' },
        { value: 'Some college', label: 'Some college' },
        { value: "Bachelor's degree", label: "Bachelor's degree" },
        { value: 'Advanced degree', label: 'Advanced degree' }
      ],
      'Job type': [
        { value: 'Regular W2 employee', label: 'Regular W2 employee' },
        { value: 'Contractor/temp/other', label: 'Contractor/temp/other' },
        { value: 'Self-employed', label: 'Self-employed' }
      ],
      'Industry': [
        { value: 'Manufacturing, Construction, Extraction', label: 'Manufacturing, Construction, Extraction' },
        { value: 'Retail, Wholesale, Transportation', label: 'Retail, Wholesale, Transportation' },
        { value: 'Hospitality, Property services', label: 'Hospitality, Property services' },
        { value: 'Healthcare', label: 'Healthcare' },
        { value: 'Education, Public administration', label: 'Education, Public administration' },
        { value: 'Business, Real estate, Technical services', label: 'Business, Real estate, Technical services' },
        { value: 'Arts, Entertainment, Personal services', label: 'Arts, Entertainment, Personal services' }
      ]
    }
  }
  
  get variables () {
    return [
      { value: 'Gender', label: 'Gender' },
      { value: 'Region', label: 'Job location' },
      { value: 'Age', label: 'Age' },
      { value: 'Race/ethnicity', label: 'Race/ethnicity' },
      { value: 'Education', label: 'Education' },
      { value: 'Job type', label: 'Job type' },
      { value: 'Industry', label: 'Industry' }
    ]
  }
  
  /* 
    Slices the data to only include rows that match the selected comparison variable.
  */
  sliceData (selectedComparison, data) {
    return data.filter(row => row.Variable === selectedComparison.value)
  }
  
  initOptions (filterKey) {
    const options = this.options[filterKey]
    
    const selectionA = this.options[filterKey][0]
    const selectionB = this.options[filterKey][1]
    
    const optionsA = this.setOptions(options, selectionB)
    const optionsB = this.setOptions(options, selectionA)

    return {
      a: {
        options: optionsA,
        selection: selectionA
      },
      b: {
        options: optionsB,
        selection: selectionB
      }
    }
  }
  
  setOptions (options, oppositeSelection) {
    return options.map(option => {
      if (option.value === oppositeSelection.value) {
        return {
          ...option,
          isDisabled: true
        }
      } else {
        return {
          ...option,
          isDisabled: false
        }
      }
    })
  }
  
  updateOptions (options, optionKey, newSelection) {
    // Need to do two things:
    // 1. update the options with the newSelection
    // 2. set the opposite options as disabled = true 
    //    (aka, we don't want both selections to have the same values, so disable one)
    // Then we can return the updated options
    let selectionA = optionKey === 'a' ? newSelection : options.a.selection
    let selectionB = optionKey === 'b' ? newSelection : options.b.selection
    
    if (optionKey === 'a') {
      selectionA = newSelection
    } else {
      selectionB = newSelection
    }
    
    const optionsA = this.setOptions(options.a.options, selectionB)
    const optionsB = this.setOptions(options.b.options, selectionA)
    
    return {
      a: {
        options: optionsA,
        selection: selectionA
      },
      b: {
        options: optionsB,
        selection: selectionB
      }
    }
  }
  
  filterData (data, filters) {
    const a = filters.a.selection
    const b = filters.b.selection
    
    return data.filter(row => {
      if (row.Group1 === a.value && row.Group2 === b.value) {
        return row
      }
      
      if (row.Group1 === b.value && row.Group2 === a.value) {
        return row
      }
    }).map(row => {
      if (row.Group1 === b.value && row.Group2 === a.value) {
        return {
          ...row,
          // Switch the values...be careful!
          'Group1': row.Group2,
          'Group2': row.Group1,
          'Stat1': row.Stat2,
          'Stat2': row.Stat1
        }
      } else {
        return row
      }
    })
  }
}