import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import CompareTool from '../components/CompareTool'

export default function ComparePage () {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "compare-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      site {
        siteMetadata {
          siteTitle
          siteDescription
          author
          siteUrl
          siteImage
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='Compare workers' data={data.site} />
      <Img fluid={data.bannerImage.childImageSharp.fluid} style={{ maxHeight: '450px' }} alt='compare workers banner image' />
      <div className='container'>
        <section className='my-4 container'>
          <h1 className='text-center'>Compare Workers</h1>
          <p className='lead'>Dive into the results of our survey and compare workers by job location and demographic groups to see how employment quality varies across Illinois.</p>
          <div className='d-flex justify-content-centent align-items-center m-2'>
            <div className='mr-2' style={{ width: '25px', height: '25px', backgroundColor: '#c3e6cb' }}>
            </div> = Statistically better value
          </div>
          <CompareTool />
        </section>
      </div>
    </Layout>
  )
}